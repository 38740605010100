  
@import "./mixins.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

:root {
  --color: #5CE54E;
  --colorLight: #ffffff;
  --colorDark: #141518;
  --colorGrayDark: #40414A;
}


body {
    font-family: MatterSQ;
    margin: 0;
}

.section {
    max-width: percentage(10/12);
    margin-left: auto;
    margin-right: auto;
}

.container {
    padding: 25px 0;

    @include breakpoint('medium') {
        padding: spacing-get-value('jumbo') 0 !important;
    }
}

// fonts
h1 {
    font-family: MatterSQBold;
    font-size: 12vw;
    letter-spacing: -0.1vw;
    line-height: 14vw;
    margin: 0;

    @include breakpoint('medium') {
        font-size: 12vw;
        letter-spacing: -0.1vw;
        line-height: 13vw;
    }

    @include breakpoint('jumbo') {
        font-size: 128px;
        line-height: 132px;
        letter-spacing: -1px;
    }
}

h2 {
    font-family: MatterSQBold;
    margin: 0;
    font-size: 10vw;
    line-height: 13vw;
    letter-spacing: -0.1vw;

 
    @include breakpoint('medium') {
        font-size: 7vw;
        line-height: 7.5vw;
        letter-spacing: -0.1vw;
    }
    
    @include breakpoint('jumbo') {
        font-size: 98px;
        line-height: 104px;
        letter-spacing: -0.5px;
    }
}

h3 {
    font-family: MatterSQBold;
    margin: 0;
    font-size: 3.5vw;
    line-height: 4vw;
    letter-spacing: -0.1vw;

    @include breakpoint('medium') {
        font-size: 3.5vw;
        line-height: 4.75vw;
        letter-spacing: -0.1vw;
        margin-bottom: 32px;
    }

    @include breakpoint('jumbo') {
        font-size: 48px;
        line-height: 52px;
        letter-spacing: -0.5px;
    }
}

h4 {
    font-family: MatterSQBold;
    margin: 0;
    text-transform: uppercase;
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 0.1vw;


    @include breakpoint('medium') {
        color: var(--color);
        font-size: 2.75vw;
        line-height: 2vw;
        letter-spacing: 0.1vw;
    }

    @include breakpoint('jumbo') {
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 1px;
    }
}

h5 {
    margin: 0;
    font-size: 4vw;
    line-height: 6vw;

    @include breakpoint('large') {
        font-size: 3vw;
        line-height: 4vw;
    }
}

p {
    font-size: 5vw;
    line-height: 8vw;

    @include breakpoint('medium') {
        font-size: 3.5vw;
        line-height: 5vw;
    }

    @include breakpoint('large') {
        font-size: 34px;
        line-height: 58px;
    }

    mark {
        background-color: var(--colorLight);
        line-height: 0;
    }
}

.eyebrow {
    font-family: MatterSQBold;
    font-weight: bold;
    align-items: center;
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 0.1vw;

    @include breakpoint('medium') {
        font-size: 1.75vw;
        line-height: 2vw;
        letter-spacing: 0.1vw;
    }

    p {
        margin: 0;
    }
}


.fThat {
    font-size: 26vw;
    line-height: 26vw;

    @include breakpoint('jumbo') {
        font-size: 250px;
        line-height: 264px;
        letter-spacing: -10px;
    }

}


mark {
    background: var(--color);
    line-height: 15vw;
    padding-left: 4px;
    padding-right: 4px;

    @include breakpoint('medium') {
        line-height: 12vw;
        padding-left: 6px;
        padding-right: 6px;
    }

    @include breakpoint('jumbo') {
        line-height: 98px;
        line-height: 184px;
    }
}

.bold {
    font-family: MatterSQBold;
}

.light {
    font-family: MatterSQLight;
}

button {
    background-color: transparent;
    border: 3px solid var(--color);
    cursor: pointer;
    font-size: 14px;    
    font-weight: bold;
    padding: 11px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
        background-color: var(--color);
        color: var(--colorDark);
    }

    @include breakpoint('medium') {
        border: 4px solid var(--color);
        font-size: 18px;
        padding: 15px;
    }

    @include breakpoint('large') {
        border: 5px solid var(--color);
        font-size: 22px;
        padding: 18px;
    }
}

a {
    cursor: pointer;
}

// COLOR
.fontColor {
    color: var(--color);
}

.fontLight {
    color: var(--colorLight);
}

.fontDark {
    color: var(--colorDark);
}

.color { 
    background-color: var(--color)
}

.colorDark {
    background-color: var(--colorDark);
}

.colorLight {
    background-color: var(--colorLight);
}

// RESPONSIVE TREATMENT
.mobile {
    display: block;
    @include breakpoint('medium') {
        display: none;
    }
}

.desktop {
    display: none;
    @include breakpoint('medium') {
        display: block;
    }
}

.displayFlex {
    display: flex;
}

.flexDirectionColumn {
    flex-direction: column;
}

img {
    object-fit: contain;
    width: 100%;
}

// SPACING
.space {
    padding: spacing-get-value('small') 0;
    @include breakpoint('medium') {
        padding:spacing-get-value('medium') 0;
    }
}


.flipResponsiveOrder {
    display: flex;
    flex-direction: column;
    @include breakpoint('medium') {
        flex-direction: column-reverse;
    }
}

// HEADER 
.header {
    padding: 25px 0;
    position: sticky;
    top: 0;
    z-index: 1000;
}

// HERO
.whatBS {
    padding-top: spacing-get-value('medium');

    @include breakpoint('medium') {
        position: absolute;
        top: 120px;
    }
    @include breakpoint('large') {
        position: absolute;
        top: 240px;
    }
}

.videoHero {
    text-align: right;

    @include breakpoint('medium') {
        padding-bottom: 20px;
    }
    @include breakpoint('large') {
        padding-bottom: 140px;
    }
}
.aspectRatio {
    position: relative;

    &:after {
        display: block;
        content: '';
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
    }

    & .videoStill {
       position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../src/static/coe-optimize.gif');
        background-position: center;
        background-size: cover;
    }
}

.playIcon {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
    margin: auto;
    width: 70px;

    @include breakpoint('medium') {
        width: spacing-get-value('jumbo');
    }
}

.modal-video {
    background-color: rgba(0,0,0,.95);
}

// IT PUTS
.itPutsSticky {
    position: -webkit-sticky;
    position: sticky;
    top: calc(100vh/2);
    transform: translateY(-50%);
}

.line {
    .space &  {
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type {
            padding-bottom: 0;
        }
    }
}

.itPutsSubdued {
    color: var(--colorGrayDark);
    transition: all 300ms ease-out;
}

.isPutsHighlight {
    color: var(--colorLight);
    transition: all 300ms ease-in;
}

.itPuts {
    font-size: 22px;

    @include breakpoint('medium') {
        font-size: 36px;
    }

    @include breakpoint('large') {
        font-size: 48px;
    }
    
}

// PARALLAX 
.parallax {
    overflow: hidden;

    mark {
        line-height: 15.5vw;

        @include breakpoint('medium') {
            line-height: 5.5vw;
        }

        @include breakpoint('jumbo') {
            line-height: 80px;
        }
    }
}

#image {
    margin-bottom: spacing-get-value('small');
    margin-left: spacing-get-value('small');
    margin-top: spacing-get-value('small');
    max-height: 250px;
    
    @include breakpoint('medium') {
        margin-left: 0;
        margin-top: 0;
        max-height: 400px;
    }
}

// TWITTER SHARE
.hashtag {
    display: flex;
    margin-bottom: 15px;

    @include breakpoint('medium') {
        margin-bottom: 20px;
    }
    @include breakpoint('large') {
        margin-bottom: 30px;
    }
}

.hashtagMark {
    // h2 increase line height

    h2 {
        @include breakpoint('medium') {
            line-height: 9.5vw;
        }
        @include breakpoint('large') {
            line-height: inherit;
        }
    }
    mark {
        line-height: 15.5vw;

        @include breakpoint('medium') {
            line-height: 11vw;
        }

        @include breakpoint('jumbo') {
            line-height: 128px;
        }
    }
}

// FOOTER
.footer {
    
    & p {
        font-size: 4vw;
        line-height: 6vw;

        @include breakpoint('medium') {
            font-size: 2.5vw;
            line-height: 4vw;
        }   

        @include breakpoint('large') {
            font-size: 2vw;
            line-height: 3.5vw;
        }

    }

    & .copyright {
        font-size: 2.5vw;

        @include breakpoint('medium') {
            font-size: 2vw;
        }
    }

   @include breakpoint('large') {
        & h4 {
            font-size: 1.75vw;
            letter-spacing: 0.1vw;
            line-height: 2vw;
        }
   }
}

p:first-of-type {
    margin-top: 0;
}