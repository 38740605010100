// Breakpoints, setter and mixins for mobile-first layouts
// =============================================================================

$-breakpoints: (
  base: 0,
) !default;

@mixin breakpoint-set($breakpoint-name, $min-width) {
  $-breakpoints: map-merge(
    $-breakpoints,
    (
      $breakpoint-name: $min-width,
    )
  ) !global;
}

@mixin breakpoint($value: 0) {
  $min-width: map-get($-breakpoints, $value) or $value;
  @if $min-width > 0 {
    @media only screen and (min-width: #{$min-width}) {
      @content;
    }
  } @else {
    @content;
  }
}

// Default Breakpoints
// =============================================================================
@include breakpoint-set(small, 576px);
@include breakpoint-set(medium, 768px);
@include breakpoint-set(large, 992px);
@include breakpoint-set(jumbo, 1440px);


// Spacing (margin and padding), setter and mixins for mobile-first layouts
// =============================================================================

$-spacing: () !default;

@mixin spacing-set($spacing-name, $value) {
  $-spacing: map-merge(
    $-spacing,
    (
      $spacing-name: $value,
    )
  ) !global;
}

@function spacing-get-value($spacing-name) {
  @if map-has-key($-spacing, $spacing-name) {
    @return map-get($-spacing, $spacing-name);
  } @else {
    @warn 'No spacing of #{$spacing-name} found.';
  }
}

// Default Spacing Sizes
// =============================================================================

@include spacing-set(small, 25px);
@include spacing-set(medium, 50px);
@include spacing-set(jumbo, 100px);
